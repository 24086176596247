<template>
  <main @keyup="onKeyUp">
    <div class="container user-auth__container">
      <!-- :class="{ '-center': !banner }" -->
      <div class="user__body user-auth__body">
        <!-- Header -->
        <header class="user__header user-auth__header">
          <h1 class="user__title">{{ $t('user-portal.user_login_title')}}</h1>
        </header>

        <!-- Login -->
        <section
          class="user__login flow"
          v-if="!lostPassword">
          <forms-login
            :errors="errors"
            :intent="confirmRoute"
            :key="key"
            @lost-password="onLostPasswordShow"
            v-model="user"
          />

          <actions-button
            appearance="primary"
            class="fill"
            :disabled="isLogging"
            :loading="isLogging"
            @click="onLogin"
          >{{ $t('user-portal.action_confirm') }}</actions-button>
        </section>

        <!-- Lost Password -->
        <section
          class="user__login flow"
          v-if="lostPassword">
          <forms-lost-password
            :errors="errors"
            v-model="userPassword"
          />

          <actions-button
            appearance="primary"
            class="fill"
            :disabled="isLogging"
            :loading="isLogging"
            @click="onLostPasswordClick"
          >{{ $t('user-portal.action_confirm') }}</actions-button>
        </section>

        <!-- Subscribe -->
        <section class="user-auth__alt user__subscribe">
          <div class="user-auth__label">{{ $t('user-portal.user_user_login_subscribe') }}</div>

          <actions-button
            :href="signupRoute"
          >{{ $t('user-portal.action_signup') }}</actions-button>
        </section>
      </div>

      <div 
        class="user-auth__illustration" 
        v-if="banner">
        <img
          class="user-auth__figure²"
          :src="banner"
        />
      </div>
      
      <div 
        class="user-auth__illustration -placeholder" 
        :style="`--placeholder-image: url(${placeholder})`"
        v-if="!banner">
      </div>
    </div>
  </main>
</template>

<script>
import FormsLogin from '@/modules/user/components/forms/login'
import FormsLostPassword from '@/modules/user/components/forms/lost-password'
import MixinError from '@/helpers/errors'

import Config from '@/config'

export default {
  name: 'UserLoginView',

  inject: [
    '$embed',
    '$loyalty',
    '$user',
    '$localStorage',

    '$redirect'
  ],

  components: {
    FormsLogin,
    FormsLostPassword
  },

  mixins: [
    MixinError,
  ],

  data() {
    return {
      errors: {},
      key: 1,
      lostPassword: false,
      userPassword: {},
      isLogging: false
    }
  },

  computed: {
    signupRoute() {
      let name = this.$route.name.replace('login', 'signup')
      return { name }
    },

    banner() {
      let ret = null
      let banners = this.$basil.get(this.embed, 'banners', [])

      if(banners && banners.length > 0) {
        ret = banners[0]
      }

      return ret
    },

    confirmRoute() {
      if(this.$route.query.redirect) {
        let name = this.$route.query.redirect
        delete this.$route.query.redirect
        return { name, query: this.$route.query }
      }

      if(this.$route.query.route) {
        let name = this.$route.query.route
        delete this.$route.query.route
        let ret = { name, query: this.$route.query }

        let params = this.$route.query.params
        if(params) {
          ret.params = JSON.parse(decodeURIComponent(params))
          delete this.$route.query.params
        }
        
        return ret
      }

      const landingPage = this.$basil.get(this.embed, 'landingPage', null)
      
      return !this.$basil.isNil(landingPage) ?
        { name: landingPage } :
        { name: 'sayl-user-portal.home' }
    },

    embed() {
      return this.$basil.get(this.$embed, 'embed')
    },

    placeholder() {
      return Config.cdn + 'statics/images/logos/resto/logomark.png'
    },

    program() {
      return this.$loyalty.program
    },

    user() {
      return this.$user.user
    },
  },

  methods: {
    onKeyUp(ev) {
      if(ev.code === 'Enter') {
        this.onLogin()
      }
    },

    onLostPasswordShow() {
      this.userPassword = {
        email: this.user.email || null,
        token: null,
        isTokenShown: false,
        password: null,
        passwordConfirm: null
      }

      this.errors = {}
      this.lostPassword = true
    },

    async onLostPasswordClick() {
      try {
        this.errors = {}
        this.isLogging = true

        if(!this.userPassword.isTokenShown) {
          await this.$user.lostPassword({ email: this.userPassword.email })
          this.userPassword.isTokenShown = true
          return
        }
        
        await this.$user.changePassword({ 
          email: this.userPassword.email, 
          token: this.userPassword.token, 
          password: this.userPassword.password, 
          passwordConfirm: this.userPassword.passwordConfirm 
        })

        this.$router.replace(this.confirmRoute).catch((e) => {})
      } catch(e) {
        this.handleErrors(e, 'lost-password')
      } finally {
        this.key++
        this.isLogging = false
      }
    },

    async onLogin() {
      try {
        this.isLogging = true
        this.errors = {}

        let user = await this.$user.login({ 
          email: this.user.email, 
          password: this.user.password, 
          redirect: this.$redirect.redirect, 
          targetEmbedId: this.$redirect.target
        })

        let token = this.$localStorage['firebase-token']
        let context = this.$localStorage.context

        if(context === 'mobile' && token) {
          await this.$user.setFirebaseToken({ token })
        }

        sayl.injekt.sp.userId = user.id

        if(this.$redirect.redirect != null) {
          window.location.href = this.$redirect.redirect
        } else {
          this.$router.push(this.confirmRoute).catch(() => {})
        }
      } catch(e) {
        this.handleErrors(e)
      } finally {
        this.isLogging = false
        this.key++
      }
    },
  },

  mounted() {
    this.errors = {}
    this.lostPassword = false
    
    let email = sessionStorage.getItem('email')
    if(email) {
      this.$user.user.email = email
    }
  }
}
</script>
